import PropTypes from "prop-types";
import { createContext } from "react";
// hooks
import useLocalStorage from "../hooks/useLocalStorage";
// config
import { defaultSettings } from "../config";

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
  onChangeMode: () => {},
  onChangeTypographyMode: () => {},
  onChangeDirection: () => {},
  onToggleStretch: () => {},
  onChangeLayout: () => {},
  onResetSetting: () => {},
};

const SettingsContext = createContext(initialState);

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage("settings", {
    themeMode: initialState.themeMode,
    typographyMode: initialState.typographyMode,
    themeDirection: initialState.themeDirection,
    themeStretch: initialState.themeStretch,
    themeLayout: initialState.themeLayout,
  });

  const onChangeMode = (value) => {
    setSettings({
      ...settings,
      themeMode: value,
    });
  };

  const onChangeTypographyMode = (value) => {
    setSettings({
      ...settings,
      typographyMode: value,
    });
  };

  const onChangeDirection = (event) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  };

  const onChangeLayout = (value) => {
    setSettings({
      ...settings,
      themeLayout: value,
    });
  };

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      typographyMode: initialState.typographyMode,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeDirection: initialState.themeDirection,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        // Typography
        onChangeTypographyMode,
        // Direction
        onChangeDirection,
        // Stretch
        onToggleStretch,
        // Navbar Horizontal
        onChangeLayout,
        // Reset Setting
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
