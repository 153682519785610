import { alpha } from "@mui/material/styles";

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const PRIMARY = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
};
const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
};
const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
};
const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
};
const WARNING = {
  lighter: "#C8FACD",
  light: "#5BE584",
  main: "#00AB55",
  dark: "#007B55",
  darker: "#005249",
};
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
};

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: "#fff" },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const getAdaptiveTextColor = (
  background,
  lightColor = "#fff",
  darkColor = "#000"
) => {
  const isLightBackground = parseInt(background.slice(1), 16) > 0xffffff / 2;
  return isLightBackground ? darkColor : lightColor;
};

const createTextColors = (background) => {
  return {
    primary: getAdaptiveTextColor(background.default),
    secondary: getAdaptiveTextColor(background.paper, "#e9e9e9", "#454F5B"),
    disabled: getAdaptiveTextColor(background.neutral, "#cacaca", "#919EAB"),
  };
};

const palette = {
  light: {
    ...COMMON,
    mode: "light",
    primary: {
      lighter: "#D1E9FC",
      light: "#76B4F1",
      main: "#1E88E5",
      dark: "#1565C0",
      darker: "#0D47A1",
      contrastText: "#fff",
    },
    text: createTextColors({
      paper: "#ffffff",
      default: "#ffffff",
      neutral: GREY[600],
    }),
    background: {
      paper: "#ffffff",
      default: "#ffffff",
      neutral: GREY[200],
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: "dark",
    primary: {
      lighter: "#B39DDB",
      light: "#7E57C2",
      main: "#673AB7",
      dark: "#512DA8",
      darker: "#311B92",
      contrastText: "#fff",
    },
    text: createTextColors({
      paper: "#fff",
      default: "#fff",
      neutral: GREY[400],
    }),
    background: {
      paper: "#303030",
      default: "#212121",
      neutral: GREY[800],
    },
    action: { active: GREY[400], ...COMMON.action },
  },
  blue: {
    ...COMMON,
    mode: "blue",
    primary: {
      lighter: "#D1E9FC",
      light: "#76B4F1",
      main: "#1E88E5",
      dark: "#1565C0",
      darker: "#0D47A1",
      contrastText: "#fff",
    },
    text: createTextColors({
      paper: "#ffffff",
      default: "#ffffff",
      neutral: GREY[600],
    }),
    background: {
      paper: "#f1f9ff",
      default: "#e3f2fd",
      neutral: GREY[300],
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  orange: {
    ...COMMON,
    mode: "orange",
    primary: {
      lighter: "#FFE0B2",
      light: "#FFB74D",
      main: "#FF9800",
      dark: "#F57C00",
      darker: "#E65100",
      contrastText: "#fff",
    },
    text: createTextColors({
      paper: "#ffffff",
      default: "#ffffff",
      neutral: GREY[600],
    }),
    background: {
      paper: "#FFF3E0",
      default: "#FFE0B2",
      neutral: GREY[300],
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  cyan: {
    ...COMMON,
    mode: "cyan",
    primary: {
      lighter: "#B2EBF2",
      light: "#4DD0E1",
      main: "#00BCD4",
      dark: "#0097A7",
      darker: "#006064",
      contrastText: "#fff",
    },
    text: createTextColors({
      paper: "#ffffff",
      default: "#ffffff",
      neutral: GREY[600],
    }),
    background: {
      paper: "#E0F7FA",
      default: "#B2EBF2",
      neutral: GREY[300],
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  red: {
    ...COMMON,
    mode: "red",
    primary: {
      lighter: "#FFCDD2",
      light: "#EF9A9A",
      main: "#F44336",
      dark: "#D32F2F",
      darker: "#B71C1C",
      contrastText: "#fff",
    },
    text: createTextColors({
      paper: "#ffffff",
      default: "#ffffff",
      neutral: GREY[600],
    }),
    background: {
      paper: "#FFEBEE",
      default: "#FFCDD2",
      neutral: GREY[300],
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  purple: {
    ...COMMON,
    mode: "purple",
    primary: {
      lighter: "#E1BEE7",
      light: "#CE93D8",
      main: "#AB47BC",
      dark: "#8E24AA",
      darker: "#6A1B9A",
      contrastText: "#fff",
    },
    text: createTextColors({
      paper: "#ffffff",
      default: "#ffffff",
      neutral: GREY[600],
    }),
    background: {
      paper: "#F3E5F5",
      default: "#E1BEE7",
      neutral: GREY[300],
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  default: {
    ...COMMON,
    mode: "default",
    primary: {
      lighter: "#B3E5FC",
      light: "#81D4FA",
      main: "#03A9F4",
      dark: "#0288D1",
      darker: "#01579B",
      contrastText: "#fff",
    },
    text: createTextColors({
      paper: "#ffffff",
      default: "#ffffff",
      neutral: GREY[600],
    }),
    background: {
      paper: "#E3F2FD",
      default: "#B3E5FC",
      neutral: GREY[300],
    },
    action: { active: GREY[600], ...COMMON.action },
  },
};

export default palette;
