import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  exchangerates: { count: 0, data: [], num_pages: 1, page: 1, page_size: "5" },
};

const slice = createSlice({
  name: "exchangerate",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EXCHANGES
    getExchangeRateSuccess(state, action) {
      state.isLoading = false;
      state.exchangerates = action.payload;
    },

    // ADD EXCHANGE
    addExchangeRateSuccess(state, action) {
      const exchangerate = action.payload;
      state.exchangerates.data.push(exchangerate);
      state.exchangerates.count += 1;
    },

    // UPDATE EXCHANGES
    updateExchangeRateSuccess(state, action) {
      const exchangerate = action.payload;
      if (state.exchangerates && state.exchangerates.data) {
        const exchangerateIndex = state.exchangerates?.data?.findIndex(
          (obj) => obj.id === exchangerate.id
        );
        if (exchangerateIndex !== -1) {
          state.isLoading = false;
          state.exchangerates.data[exchangerateIndex] = exchangerate;
        }
      } else {
        state.exchangerates = { data: [] };
        state.exchangerates.data.push(exchangerate);
      }
    },

    // DELETE EXCHANGE
    deleteExchangeRateSuccess(state, action) {
      const deletedExchangeRateId = action.payload;
      state.exchangerates.data = state.exchangerates.data.filter(
        (exchangerate) => exchangerate.id !== deletedExchangeRateId
      );
      state.isLoading = false;
    },

    // DELETE EXCHANGES
    deleteExchangesRateSuccess(state, action) {
      const deletedExchangeRateIds = action.payload;
      state.exchangerates.data = state.exchangerates.data.filter(
        (exchangerate) => !deletedExchangeRateIds.includes(exchangerate.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addExchangeRateSuccess,
  updateExchangeRateSuccess,
  deleteExchangeRateSuccess,
  deleteExchangesRateSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getExchangeRates(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/exchangerates?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getExchangeRateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getExchangeRate(keyId) {
  const response = await axios.get(
    `/api/dashboard/exchangerate-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addExchangeRate(data) {
  const response = await axios.post(`/api/dashboard/exchangerates`, data);
  return response.data;
}

export async function updateExchangeRate(data) {
  const response = await axios.put(`/api/dashboard/exchangerates`, data);
  return response.data;
}

export async function deleteExchangeRate(keyId) {
  const response = await axios.delete(`/api/dashboard/exchangerates`, {
    data: { keyId },
  });
  return response.data;
}

export async function deleteExchangesRate(keyIds) {
  const response = await axios.delete(`/api/dashboard/exchangerate-id`, {
    data: { keyIds },
  });
  return response.data;
}
