import { styled } from "@mui/material/styles";
import { Grid, RadioGroup, CardActionArea } from "@mui/material";
import useSettings from "../../hooks/useSettings";
import { typographies } from "../../theme/typography";
//
import { BoxMask } from ".";

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 72,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: theme.shadows[3],
  },
}));

export default function SettingTypographyMode() {
  const { typographyMode, onChangeTypographyMode } = useSettings();

  const typographyKeys = Object.keys(typographies);

  return (
    <RadioGroup
      name="typographyMode"
      value={typographyMode}
      onChange={(event) => onChangeTypographyMode(event.target.value)}
    >
      <Grid dir="ltr" container spacing={2.5}>
        {typographyKeys.map((font) => {
          const currentTypography = typographies[font];

          const fontFamily = currentTypography?.fontFamily || "sans-serif";

          const isSelected = typographyMode === font;

          return (
            <Grid key={font} item xs={6}>
              <BoxStyle
                sx={{
                  fontFamily,
                  color: 'text.primary',
                  ...(isSelected && {
                    color: (theme) => theme.palette.primary.main,
                    boxShadow: (theme) => theme.customShadows.z20,
                    borderColor: (theme) => theme.palette.primary.main,
                  }),
                }}
              >
                <span style={{ fontFamily, fontWeight: 'bold' }}>Exemple text</span>
                <BoxMask value={font} />
              </BoxStyle>
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
}
