import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  limit_transaction_generales: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "limit_transaction",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LIMIT TRANSACTION GENERALE
    getLimitTransactionGeneraleSuccess(state, action) {
      state.isLoading = false;
      state.limit_transaction_generales = action.payload;
    },

    // ADD LIMIT TRANSACTION GENERALE
    addLimitTransactionGeneraleSuccess(state, action) {
      const limittransaction = action.payload;
      state.limit_transaction_generales.data.push(limittransaction);
      state.limit_transaction_generales.count += 1;
    },

    // UPDATE LIMIT TRANSACTION GENERALES
    updateLimitTransactionGeneraleSuccess(state, action) {
      const limittransaction = action.payload;
      if (
        state.limit_transaction_generales &&
        state.limit_transaction_generales.data
      ) {
        const limittransactionIndex =
          state.limit_transaction_generales?.data?.findIndex(
            (obj) => obj.id === limittransaction.id
          );
        if (limittransactionIndex !== -1) {
          state.isLoading = false;
          state.limit_transaction_generales.data[limittransactionIndex] =
            limittransaction;
        }
      } else {
        state.limit_transaction_generales = { data: [] };
        state.limit_transaction_generales.data.push(limittransaction);
      }
    },

    // DELETE LIMIT TRANSACTION GENERALE
    deleteLimitTransactionGeneraleSuccess(state, action) {
      const deletedLimitTransactionGeneraleId = action.payload;
      state.limit_transaction_generales.data =
        state.limit_transaction_generales.data.filter(
          (limittransaction) =>
            limittransaction.id !== deletedLimitTransactionGeneraleId
        );
      state.isLoading = false;
    },

    // DELETE LIMIT TRANSACTION GENERALES
    deleteLimitTransactionGeneralesSuccess(state, action) {
      const deletedLimitTransactionGeneraleIds = action.payload;
      state.limit_transaction_generales.data =
        state.limit_transaction_generales.data.filter(
          (limittransaction) =>
            !deletedLimitTransactionGeneraleIds.includes(limittransaction.id)
        );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addLimitTransactionGeneraleSuccess,
  updateLimitTransactionGeneraleSuccess,
  deleteLimitTransactionGeneraleSuccess,
  deleteLimitTransactionGeneralesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLimitTransactionGenerales(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/limit-transaction-generales?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getLimitTransactionGeneraleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getLimitTransactionGenerale(keyId) {
  const response = await axios.get(
    `/api/dashboard/limit-transaction-generale-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addLimitTransactionGenerale(data) {
  const response = await axios.post(
    `/api/dashboard/limit-transaction-generales`,
    data
  );
  return response.data;
}

export async function updateLimitTransactionGenerale(data) {
  const response = await axios.put(
    `/api/dashboard/limit-transaction-generales`,
    data
  );
  return response.data;
}

export async function deleteLimitTransactionGenerale(keyId) {
  const response = await axios.delete(
    `/api/dashboard/limit-transaction-generales`,
    {
      data: { keyId },
    }
  );
  return response.data;
}

export async function deleteLimitTransactionGenerales(keyIds) {
  const response = await axios.delete(
    `/api/dashboard/limit-transaction-generale-id`,
    {
      data: { keyIds },
    }
  );
  return response.data;
}
