import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import quizpricingReducer from "./slices/quizpricing";
import quizpvpReducer from "./slices/quizpvp";
import exchangerateReducer from "./slices/exchangerate";
import generaleReducer from "./slices/generale";
import annonceReducer from "./slices/annonces";
import usersReducer from "./slices/users";
import friendsReducer from "./slices/friends";
import notificationsReducer from "./slices/notifications";
import agentReducer from "./slices/agent";
import customerReducer from "./slices/customer";
import feeReducer from "./slices/fee";
import supervisorReducer from "./slices/supervisor";
import limittransactionReducer from "./slices/limittransaction";
import countryReducer from "./slices/countries";
import questionReducer from "./slices/questions";
import categoryReducer from "./slices/categories";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  generale: generaleReducer,
  exchangerate: exchangerateReducer,
  supervisor: supervisorReducer,
  agent: agentReducer,
  customer: customerReducer,
  fee: feeReducer,
  quizpricing: quizpricingReducer,
  quizpvp: quizpvpReducer,
  limit_transaction: limittransactionReducer,
  annonce: annonceReducer,
  country: countryReducer,
  questions: questionReducer,
  categories: categoryReducer,
  users: usersReducer,
  friends: friendsReducer,
  notifications: notificationsReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
