import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession, setSessionSite } from "../utils/jwt";
import useLocales from "../hooks/useLocales";
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  update: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { onChangeLang } = useLocales();

  useEffect(() => {
    const initialize = async () => {
      const site_ = await axios.get("/api/v1/user/auth/config");
      setSessionSite(site_.data);
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/api/v1/user/auth/me");
          const user = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.log(err?.response.statusText);
        if (err?.response?.status === 401) {
          logout();
        }
      }
    };

    initialize();
  }, []);

  const login = async (phone_or_email_or_username, password) => {
    const response = await axios.post("/api/v1/user/auth/login", {
      phone_or_email_or_username,
      password: password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const register = async (
    phone,
    langue,
    username,
    email,
    password,
    first_name,
    last_name,
    ref_code
  ) => {
    const response = await axios.post("/api/v1/user/auth/register", {
      phone,
      langue,
      username,
      email,
      password,
      first_name,
      last_name,
      ref_code,
    });
    const { accessToken, user } = response.data;

    onChangeLang(user.langue);

    setSession(accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    try {
      await axios.post("/api/v1/user/auth/logout", {});
      setSession(null);
      dispatch({ type: "LOGOUT" });
    } catch (e) {
      setSession(null);
      dispatch({ type: "LOGOUT" });
    }
  };

  const update = async (data) => {
    const response = await axios.put("/api/v1/accounts/update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({
      type: "UPDATE",
      payload: {
        user: response.data,
      },
    });
  };

  const refresh = async () => {
    const response = await axios.get("/api/v1/accounts/user");
    const user = response.data;

    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        update,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
