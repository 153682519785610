// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const STORE_AUTH = "/store";
const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_PAGE_STORE = {
  root: STORE_AUTH,
  home: path(STORE_AUTH, "/home"),
  checkout: path(STORE_AUTH, "/checkout"),
  product_view: (id) => path(STORE_AUTH, `/product/${id}`),
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_PAGE = {
  PolitiqueConf: "politique-confidentialite",
  TermesConditions: "termes-conditions",
  ReglesJeu: "regles-jeu",
  PolitiqueRemboursement: "politique-remboursement",
  PolitiqueModeration: "politique-moderation",
  ConditionsAffiliation: "conditions-affiliation",
  PolitiqueSecurite: "politique-securite",
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
  home: "/",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/home"),
  },
  configuration_management: {
    root: path(ROOTS_DASHBOARD, "/configuration"),
  },
  configuration: {
    root: path(ROOTS_DASHBOARD, "/configuration-transaction"),
    exchange_rate: path(
      ROOTS_DASHBOARD,
      "/configuration-transaction/exchange-rate"
    ),
    new_exchange_rate: path(
      ROOTS_DASHBOARD,
      "/configuration-transaction/exchange-rate/new"
    ),
    edit_rate: (id) =>
      path(
        ROOTS_DASHBOARD,
        `/configuration-transaction/exchange-rate/${id}/edit`
      ),
    fee: path(ROOTS_DASHBOARD, "/configuration-transaction/fee"),
    new_fee: path(ROOTS_DASHBOARD, "/configuration-transaction/fee/new"),
    edit_fee: (id) =>
      path(ROOTS_DASHBOARD, `/configuration-transaction/fee/${id}/edit`),
  },
  security_and_limit: {
    root: path(ROOTS_DASHBOARD, "/security-and-limit"),
    limit_transaction_generale: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-transaction-generale"
    ),
    new_limit_transaction_generale: path(
      ROOTS_DASHBOARD,
      "/security-and-limit/limit-transaction-generale/new"
    ),
    edit_limit_transaction_generale: (id) =>
      path(
        ROOTS_DASHBOARD,
        `/security-and-limit/limit-transaction-generale/${id}/edit`
      ),
  },
  agent: {
    root: path(ROOTS_DASHBOARD, "/agent"),
    list: path(ROOTS_DASHBOARD, "/agent/list"),
    add: path(ROOTS_DASHBOARD, "/agent/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/agent/${id}/edit`),
  },
  supervisor: {
    root: path(ROOTS_DASHBOARD, "/master-agent"),
    list: path(ROOTS_DASHBOARD, "/master-agent/list"),
    add: path(ROOTS_DASHBOARD, "/master-agent/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/master-agent/${id}/edit`),
  },
  customer: {
    root: path(ROOTS_DASHBOARD, "/customer"),
    list: path(ROOTS_DASHBOARD, "/customer/list"),
    add: path(ROOTS_DASHBOARD, "/customer/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/customer/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
};

export const PATH_CONTACT = "/#/contact-us";
