// routes
import { PATH_PAGE_STORE, PATH_PAGE } from "../../routes/paths";
// components
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "Acceuil",
    icon: <Iconify icon={"eva:home-fill"} {...ICON_SIZE} />,
    path: PATH_PAGE_STORE.home,
  },
  {
    title: "About",
    icon: <Iconify icon={"wpf:about"} {...ICON_SIZE} />,
    path: PATH_PAGE.about,
  },
];

export default menuConfig;
