// @mui
import { styled } from "@mui/material/styles";
import { Grid, RadioGroup, CardActionArea } from "@mui/material";
// hooks
import useSettings from "../../hooks/useSettings";
// themes
import palette from "../../theme/palette"; // Assurez-vous que l'importation est correcte et que 'palette' contient les thèmes
//
import Iconify from "../Iconify";
import { BoxMask } from ".";

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 72,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: theme.shadows[3],
  },
}));

// ----------------------------------------------------------------------

export default function SettingMode() {
  const { themeMode, onChangeMode } = useSettings();

  // Obtenir les clés des modes de thèmes disponibles
  const themeModes = Object.keys(palette);

  return (
    <RadioGroup name="themeMode" value={themeMode} onChange={(event) => onChangeMode(event.target.value)}>
      <Grid dir="ltr" container spacing={2.5}>
        {themeModes.map((mode) => {
          const currentTheme = palette[mode]; // Accéder aux couleurs de l'arrière-plan du thème
          
          // Vérifiez si currentTheme et ses propriétés existent
          const backgroundColor = currentTheme?.background?.default || "#ffffff"; // Valeur par défaut de secours
          const primaryColor = currentTheme?.primary?.main || "#000000"; // Valeur par défaut de secours
          const textColor = currentTheme?.text?.primary || "#000000"; // Valeur par défaut de secours

          const isSelected = themeMode === mode;

          return (
            <Grid key={mode} item xs={6}>
              <BoxStyle
                sx={{
                  bgcolor: backgroundColor,
                  color: textColor,
                  ...(isSelected && {
                    color: primaryColor,
                    boxShadow: (theme) => theme.customShadows.z20,
                    borderColor: primaryColor,
                  }),
                }}
              >
                <Iconify
                  icon={
                    mode === "light"
                      ? "ph:sun-duotone"
                      : mode === "dark"
                      ? "ph:moon-duotone"
                      : "ph:palette-duotone" // Icône générique pour les autres thèmes
                  }
                  width={28}
                  height={28}
                />
                <BoxMask value={mode} />
              </BoxStyle>
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
}
