import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import StoreLayout from "../layouts/store";
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import Blank from "../layouts/Blank";
// guards
import SiteGuestGuard from "../guards/SiteGuestGuard";
import SiteAuthGuard from "../guards/SiteAuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <SiteGuestGuard>
              <Login />
            </SiteGuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <SiteGuestGuard>
              <Register />
            </SiteGuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Store Routes
    {
      path: "store",
      element: (
        <SiteGuestGuard site="STORE">
          <StoreLayout />
        </SiteGuestGuard>
      ),
      children: [
        { element: <Navigate to='/home' replace />, index: true },
        { path: "home", element: <EcommerceShop /> },
        { path: "product/:id", element: <EcommerceProductDetails /> },
        { path: "checkout", element: <EcommerceCheckout /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "politique-confidentialite", element: <PolitiqueConf /> },
        { path: "termes-conditions", element: <TermesConditions /> },
        {
          path: "politique-moderation",
          element: <PolitiqueModeration />,
        },
        {
          path: "politique-securite",
          element: <PolitiqueSecurite />,
        },
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          element: (
            <SiteGuestGuard>
              <HomePage />
            </SiteGuestGuard>
          ),
          index: true,
        },
        { path: "about-us", element: <About /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <Faqs /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

// STORE
const EcommerceShop = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceShop"))
);

const EcommerceProductDetails = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductDetails"))
);

const EcommerceCheckout = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceCheckout"))
);

// DASHBOARD

// USER
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
// MAIN
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const PolitiqueConf = Loadable(lazy(() => import("../pages/PolitiqueConf")));
const TermesConditions = Loadable(
  lazy(() => import("../pages/TermesConditions"))
);
const PolitiqueModeration = Loadable(
  lazy(() => import("../pages/PolitiqueModeration"))
);
const PolitiqueSecurite = Loadable(
  lazy(() => import("../pages/PolitiqueSecurite"))
);
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
