import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  agents: { count: 0, data: [], num_pages: 1, page: 1, page_size: "5" },
};

const slice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET AGENTS
    getAgentSuccess(state, action) {
      state.isLoading = false;
      state.agents = action.payload;
    },

    // ADD AGENT
    addAgentSuccess(state, action) {
      const agent = action.payload;
      state.agents.data.push(agent);
      state.agents.count += 1;
    },

    // UPDATE AGENT
    updateAgentSuccess(state, action) {
      const agent = action.payload;
      if (state.agents && state.agents.data) {
        const agentIndex = state.agents?.data?.findIndex(
          (obj) => obj.id === agent.id
        );
        if (agentIndex !== -1) {
          state.isLoading = false;
          state.agents.data[agentIndex] = agent;
        }
      } else {
        state.agents = { data: [] };
        state.agents.data.push(agent);
      }
    },

    // DELETE AGENT
    deleteAgentSuccess(state, action) {
      const deletedAgentId = action.payload;
      state.agents.data = state.agents.data.filter(
        (agent) => agent.id !== deletedAgentId
      );
      state.isLoading = false;
    },

    // DELETE AGENTS
    deleteAgentsSuccess(state, action) {
      const deletedAgentIds = action.payload;
      state.agents.data = state.agents.data.filter(
        (agent) => !deletedAgentIds.includes(agent.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addAgentSuccess,
  updateAgentSuccess,
  deleteAgentSuccess,
  deleteAgentsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getAgents(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/agents?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getAgentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getAgent(keyId) {
  const response = await axios.get(`/api/dashboard/agent-id?keyId=${keyId}`);
  return response.data;
}

export async function addAgent(data) {
  const response = await axios.post(`/api/dashboard/agents`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function updateAgent(data) {
  const response = await axios.put(`/api/dashboard/agents`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function deleteAgent(keyId) {
  const response = await axios.delete(`/api/dashboard/agents/${keyId}/`);
  return response.data;
}

export async function deleteAgents(keyIds) {
  const response = await axios.delete(`/api/dashboard/agent-id`, {
    data: { keyIds },
  });
  return response.data;
}
